import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { Control } from 'react-hook-form';
import { RegulationFormValues } from '../DataTable/dataTypes';

interface DocumentFieldsProps {
  control: Control<RegulationFormValues>;
  disabled?: boolean;
}

const DocumentFields: React.FC<DocumentFieldsProps> = ({
  control,
  disabled,
}) => {
  return (
    <>
      <h3>Documento</h3>
      <Controller
        name="document.pdfUrl"
        control={control}
        rules={{ required: 'La URL del pdf es un campo obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={field.value || ''}
            label="URL del PDF *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Ingrese"
            disabled={disabled} // Deshabilitar en modo solo lectura
          />
        )}
      />
      <Controller
        name="document.referenceTitle"
        control={control}
        rules={{ required: 'El título de referencia es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={field.value || ''}
            label="Título de Referencia *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Ingrese"
            disabled={disabled} // Deshabilitar en modo solo lectura
          />
        )}
      />
      <Controller
        name="document.description"
        control={control}
        rules={{ required: 'La descripción del texto es un campo obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            value={field.value || ''}
            label="Descripción *"
            fullWidth
            margin="dense"
            error={!!error}
            helperText={error ? error.message : null}
            multiline
            rows={2}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Ingrese"
            disabled={disabled} // Deshabilitar en modo solo lectura
          />
        )}
      />
    </>
  );
};

export default DocumentFields;
