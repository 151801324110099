import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

interface FileUploadModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUploadFile: () => void;
  isDisabled?: boolean;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  handleClose,
  handleFileChange,
  handleUploadFile,
  isDisabled,
}) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Subir Documento
        </Typography>
        <input type="file" onChange={handleFileChange} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            mt: 2,
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUploadFile}
            disabled={!handleFileChange}
          >
            Subir
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FileUploadModal;
