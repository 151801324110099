import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTaskInstance, deleteTaskInstance, fetchTaskInstances, updateTaskInstance as updateTaskInstanceApi } from '../../api/api';
import { TaskInstance } from '../../types/interfaces';

interface TaskInstanceState {
  taskInstances: TaskInstance[];
  loading: boolean;
}

const initialState: TaskInstanceState = {
  taskInstances: [],
  loading: false
};

export const getTaskInstances = createAsyncThunk(
  'taskInstance/getTaskInstances',
  async (userId: number, { getState, dispatch }) => {
    try {
      const taskInstances = await fetchTaskInstances(userId);
      return taskInstances;
    } catch (error) {
      console.error("Error fetching task instances:", error);
      throw error;
    }
  }
);

export const addTaskInstanceAsync = createAsyncThunk(
  'taskInstance/addTaskInstance',
  async (taskInstance: TaskInstance) => {
    const newTaskInstance = await createTaskInstance(taskInstance);
    return newTaskInstance;
  }
);

export const updateTaskInstanceAsync = createAsyncThunk(
  'taskInstance/updateTaskInstance',
  async (taskInstance: any) => {
    const updatedTaskInstance = await updateTaskInstanceApi(taskInstance, taskInstance.userId);
    return updatedTaskInstance;
  }
);

export const deleteTaskInstanceAsync = createAsyncThunk(
  'taskInstance/deleteTaskInstance',
  async (taskInstanceId: number, { rejectWithValue }) => {
    try {
      await deleteTaskInstance(taskInstanceId);
      return taskInstanceId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const taskInstanceSlice = createSlice({
  name: 'taskInstance',
  initialState,
  reducers: {
    addTaskInstance(state, action: PayloadAction<TaskInstance>) {
      state.taskInstances.push(action.payload);
    },
    updateTaskInstance(state, action: PayloadAction<TaskInstance>) {
      const index = state.taskInstances.findIndex(ti => ti.id === action.payload.id);
      if (index !== -1) {
        state.taskInstances[index] = action.payload;
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskInstances.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTaskInstances.fulfilled, (state, action) => {
        state.taskInstances = action.payload;
        state.loading = false;
      })
      .addCase(getTaskInstances.rejected, (state) => {
        state.loading = false;
        // manejar un estado de error
      })
      .addCase(addTaskInstanceAsync.fulfilled, (state, action) => {
        state.taskInstances.push(action.payload);
      })
      .addCase(updateTaskInstanceAsync.fulfilled, (state, action) => {
        const index = state.taskInstances.findIndex(ti => ti.id === action.payload.id);
        if (index !== -1) {
          state.taskInstances[index] = action.payload;
        }
      })
      .addCase(deleteTaskInstanceAsync.fulfilled, (state, action) => {
        state.taskInstances = state.taskInstances.filter(taskInstance => taskInstance.id !== action.payload);
      });
  }
});

export const { addTaskInstance, updateTaskInstance, setLoading } = taskInstanceSlice.actions;

export default taskInstanceSlice.reducer;
