import React from 'react';
import { MenuItem, Select, FormControl, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';

interface TaskStatusOptionsProps {
  userResponsibility: string | undefined;
  taskStatus: string | undefined;
  onStatusChange: (newStatus: string) => void;
  isDisabled?: boolean;
}

const TaskStatusOptions: React.FC<TaskStatusOptionsProps> = ({
  userResponsibility,
  taskStatus,
  onStatusChange,
  isDisabled,
}) => {
  const getStatusOptions = () => {
    if (userResponsibility === 'Principal') {
      return [
        'Pendiente',
        'Trabajando',
        'Revisión',
        'Revisión Rechazada',
        'Liberación',
        'Validación',
        'Validación Rechazada',
        'Finalizado',
      ];
    }

    if (userResponsibility === 'Ejecución') {
      if (
        taskStatus === 'Pendiente' ||
        taskStatus === 'Trabajando' ||
        taskStatus === 'Revisión'
      ) {
        return ['Pendiente', 'Trabajando', 'Revisión'];
      } else if (
        taskStatus === 'Revisión Rechazada' ||
        taskStatus === 'Revisión'
      ) {
        return ['Revisión Rechazada', 'Revisión'];
      } else if (taskStatus === 'Liberación' || taskStatus === 'Validación') {
        return ['Liberación', 'Validación'];
      } else if (taskStatus === 'Validación Rechazada') {
        return ['Validación Rechazada', 'Validación'];
      }
    }

    if (userResponsibility === 'Revisión') {
      if (
        taskStatus === 'Revisión' ||
        taskStatus === 'Revisión Rechazada' ||
        taskStatus === 'Liberación'
      ) {
        return ['Revisión', 'Revisión Rechazada', 'Liberación'];
      }
    }

    if (userResponsibility === 'Validación') {
      if (
        taskStatus === 'Validación' ||
        taskStatus === 'Validación Rechazada' ||
        taskStatus === 'Finalizado'
      ) {
        return ['Validación', 'Validación Rechazada', 'Finalizado'];
      }
    }

    return [];
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    onStatusChange(event.target.value);
  };

  return (
    <FormControl>
      <Typography variant="subtitle2" color={'secondary'} sx={{ mt: -2 }}>
        Estado
      </Typography>
      <Select
        value={taskStatus || ''}
        onChange={handleStatusChange}
        sx={{
          height: '30px',
          fontSize: '14px',
          minWidth: '120px',
        }}
        disabled={isDisabled}
      >
        {getStatusOptions().map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TaskStatusOptions;
