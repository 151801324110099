import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { TextField, Box, MenuItem } from '@mui/material';
import { RegulationFormValues } from '../../DataTable/dataTypes';

interface ScheduleFieldsProps {
  control: Control<RegulationFormValues>;
  taskIndex: number;
  disabled?: boolean;
}

const ScheduleFields: React.FC<ScheduleFieldsProps> = ({
  control,
  taskIndex,
  disabled,
}) => {
  return (
    <Box display={'flex'} gap={'10px'}>
      <Controller
        name={`tasks.${taskIndex}.preparationDays`}
        control={control}
        rules={{
          required: 'Días de Preparación es obligatorio',
          validate: (value) =>
            value >= 0 || 'Seleccione un número mayor o igual a 0',
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            type="number"
            label="Días de Preparación"
            error={!!error}
            helperText={error ? error.message : null}
            sx={{ width: '150px' }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: 0,
                max: 365,
                pattern: '[0-9]+',
              },
            }}
            placeholder="Ingrese"
            disabled={disabled}
          />
        )}
      />
      <Controller
        name={`tasks.${taskIndex}.firstDueDate`}
        control={control}
        rules={{ required: 'El primer plazo es obligatorio' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const localDate = new Date(e.target.value);
            localDate.setHours(localDate.getHours() + 10);
            onChange(localDate.toISOString());
          };

          const displayValue = value ? value.toString().split('T')[0] : '';

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
              }}
            >
              <TextField
                label="Fecha de cumplimiento"
                type="date"
                value={displayValue}
                onChange={handleDateChange}
                sx={{
                  borderRadius: '5px',
                  ...(error && { border: '1px solid red !important' }),
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '5px',
                    ...(error && {
                      borderColor: 'red',
                    }),
                    '&.Mui-focused fieldset': {
                      borderColor: error ? 'red' : 'primary.main',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: error ? 'red' : 'inherit',
                  },
                  '& .MuiFormHelperText-root': {
                    color: 'red',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
              />
              {error && (
                <span
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '3px',
                    marginLeft: '10px',
                  }}
                >
                  {error.message}
                </span>
              )}
            </div>
          );
        }}
      />
      <Controller
        name={`tasks.${taskIndex}.periodicity`}
        control={control}
        defaultValue={'monthly'}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={!!error}
            helperText={error ? error.message : null}
            select
            label="Periodicidad"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              placeholder: 'Seleccione',
            }}
            sx={{ width: '250px' }}
            disabled={disabled}
          >
            <MenuItem disabled value="">
              <em>Seleccione:</em>
            </MenuItem>
            <MenuItem value="weekly">Semanal</MenuItem>
            <MenuItem value="monthly">Mensual</MenuItem>
            <MenuItem value="quarterly">Trimestral</MenuItem>
            <MenuItem value="semiannually">Semestral</MenuItem>
            <MenuItem value="annually">Anual</MenuItem>
          </TextField>
        )}
      />
    </Box>
  );
};

export default ScheduleFields;
