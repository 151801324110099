import React, { useState } from 'react';
import { Box, Typography, TextField, FormControl } from '@mui/material';
import { TaskInstance } from '../../types/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import TaskStatusOptions from './TaskStatusOptions';

interface TaskStatusProps {
  editedTaskInstance: TaskInstance | null;
  dueDateValue: string | undefined;
  setDueDateValue: (value: string | undefined) => void;
  setEditedTaskInstance: React.Dispatch<
    React.SetStateAction<TaskInstance | null>
  >;
  isDisabled?: boolean;
}

const TaskStatus: React.FC<TaskStatusProps> = ({
  editedTaskInstance,
  dueDateValue,
  setDueDateValue,
  setEditedTaskInstance,
  isDisabled,
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const responsibilities =
    editedTaskInstance?.task?.taskUserResponsibilities || [];
  const userResponsibility = responsibilities.find(
    (responsibility) => responsibility.user?.id === user.id
  )?.responsibility?.description;

  const [isEditingReason, setIsEditingReason] = useState(false);

  const handleStatusChange = (newStatus: string) => {
    setEditedTaskInstance((prevTask) =>
      prevTask ? { ...prevTask, status: newStatus } : null
    );
  };

  const handleDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const date = new Date(value);
    date.setHours(10, 0, 0);
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - timezoneOffset);
    localDate.setDate(localDate.getDate() + 1);
    setDueDateValue(value);
    setEditedTaskInstance((prevTask) =>
      prevTask ? { ...prevTask, dueDate: localDate } : null
    );
  };

  const handleRejectionReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setEditedTaskInstance((prevTask) =>
      prevTask ? { ...prevTask, rejectionReason: value } : null
    );
  };

  const canEditRejection =
    ['Principal', 'Revisión'].includes(userResponsibility || '') &&
    editedTaskInstance?.status === 'Revisión Rechazada';

  const handleReasonClick = () => {
    if (canEditRejection) {
      setIsEditingReason(true);
    }
  };

  const handleReasonBlur = () => {
    setIsEditingReason(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
      <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center', pt: 2 }}>
        <TaskStatusOptions
          userResponsibility={userResponsibility}
          taskStatus={editedTaskInstance?.status}
          onStatusChange={handleStatusChange}
          isDisabled={isDisabled}
        />
        <FormControl>
          <Typography variant="subtitle2" color={'secondary'} sx={{ mt: -2 }}>
            Vencimiento
          </Typography>
          <TextField
            type="date"
            value={dueDateValue || ''}
            onChange={handleDueDateChange}
            sx={{
              '& .MuiInputBase-root': {
                height: 30,
                fontSize: 14,
              },
              '& .MuiInputLabel-root': {
                fontSize: 14,
              },
              minWidth: 120,
            }}
            InputProps={{
              readOnly: isDisabled,
            }}
            disabled={isDisabled}
          />
        </FormControl>
      </Box>

      {(editedTaskInstance?.rejectionReason || canEditRejection) && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" color={'secondary'}>
            Razón de Rechazo
          </Typography>
          {isEditingReason ? (
            <TextField
              value={editedTaskInstance?.rejectionReason || ''}
              onChange={handleRejectionReasonChange}
              onBlur={handleReasonBlur}
              multiline
              rows={3}
              variant="outlined"
              autoFocus
            />
          ) : (
            <Typography
              variant="body1"
              sx={{ mt: 1, cursor: canEditRejection ? 'pointer' : 'default' }}
              onClick={handleReasonClick}
            >
              {editedTaskInstance.rejectionReason || 'No hay razón de rechazo.'}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TaskStatus;
