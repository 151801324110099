import axios, { API_BASE_URL } from './axiosConfig';

export const fetchResponsibilities = async (): Promise<
  Array<{ id: number; type: string; description: string }>
> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/responsibilities`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching responsibilities');
    }
  } catch (error) {
    console.error('Error fetching responsibilities:', error);
    throw error;
  }
};