// notificationsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserNotifications } from '../../api/api'; 
import { Notification, NotificationsState } from '../../components/DataTable/dataTypes';

const initialState: NotificationsState = {
  notifications: {
    'Fuera de Plazo': 0,
    'Validación Rechazada': null,
    'Revisión Rechazada': 0,
  'Ejecución Atrasada': 0,
  'Liberación': 0,
  }
};

export const notificationsReducer = (state = initialState, action: any): NotificationsState => {
  switch (action.type) {
    default:
      return state;
  }
};
// Async Thunks

// Obtener las notificaciones del usuario
export const fetchNotifications = createAsyncThunk(
  'user/fetchNotifications',
  async (userId: number, { rejectWithValue }) => {
    try {
      const notifications = await fetchUserNotifications(userId);
      return notifications;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Error fetching notifications');
    }
  }
);

// Slice
const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [] as Notification[], // Ajustar el tipo si cambia la estructura
    loading: 'idle',
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = 'loading';
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.notifications = [];
        state.loading = 'failed';
        state.error = action.payload as string;
      });
  },
});

export default notificationsSlice.reducer;
