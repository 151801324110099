import React, { useEffect, useState } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { TextField, MenuItem, CircularProgress, Box } from '@mui/material';
import {
  // fetchClients,
  fetchGroups,
  fetchEmitters,
  fetchTypes,
  fetchCategories,
  fetchScopes,
} from '../../api/api';
import { RegulationFormValues } from '../DataTable/dataTypes';

interface RegulationFieldsProps {
  control: Control<RegulationFormValues>;
  disabled?: boolean;
}

interface Emitter {
  id: number;
  name: string;
}

interface Group {
  id: number;
  group: string;
}

const RegulationFields: React.FC<RegulationFieldsProps> = ({
  control,
  disabled,
}) => {
  // const [clients, setClients] = useState([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [emitters, setEmitters] = useState<Emitter[]>([]);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState('');

  const emittedBy = useWatch({ control, name: 'emittedBy.id' });
  const number = useWatch({ control, name: 'number' });
  const group = useWatch({ control, name: 'group.id' });

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        // const clientsData = await fetchClients();
        const groupsData = await fetchGroups();
        const emittersData = await fetchEmitters();
        const typesData = await fetchTypes();
        const categoriesData = await fetchCategories();
        const scopesData = await fetchScopes();
        // setClients(clientsData);
        setGroups(groupsData);
        setEmitters(emittersData);
        setTypes(typesData);
        setCategories(categoriesData);
        setScopes(scopesData);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    const emitterName =
      emitters.find((emitter) => emitter.id === emittedBy)?.name || '';
    const groupName =
      groups.find((groupItem) => groupItem.id === group)?.group || '';
    if (emitterName && number && groupName) {
      const generatedCode = `${emitterName}-${number}-${groupName}`;
      setCode(generatedCode);
    }
  }, [emittedBy, number, group, emitters, groups]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <h3>Normativa</h3>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={code}
              label="Código interno"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={true} // Campo siempre deshabilitado
            />
          )}
        />
        <Controller
          name="emittedBy.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Emitido Por *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled} // Deshabilitar en modo solo lectura
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {emitters.map((emitter: any) => (
                <MenuItem key={emitter.id} value={emitter.id}>
                  {emitter.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="number"
          control={control}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Número de registro *"
              fullWidth
              margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Ingrese"
              disabled={disabled} // Deshabilitar en modo solo lectura
            />
          )}
        />
        <Controller
          name="group.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              label="Grupo"
              fullWidth
              margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled} // Deshabilitar en modo solo lectura
            >
              <MenuItem disabled value={0}>
                <em>Seleccione:</em>
              </MenuItem>
              {groups.map((group: any) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.group}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Controller
          name="type.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Tipo *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled} // Deshabilitar en modo solo lectura
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {types.map((type: any) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="category.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error ? error.message : null}
              select
              label="Categoría *"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled} // Deshabilitar en modo solo lectura
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {categories.map((category: any) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.category}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="scope.id"
          control={control}
          defaultValue={0}
          rules={{ required: 'Campo requerido' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              select
              label="Alcance *"
              fullWidth
              margin="dense"
              error={!!error}
              helperText={error ? error.message : null}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                placeholder: 'Seleccione',
              }}
              disabled={disabled} // Deshabilitar en modo solo lectura
            >
              <MenuItem disabled value={0}>
                <em>Seleccione</em>
              </MenuItem>
              {scopes.map((scope: any) => (
                <MenuItem key={scope.id} value={scope.id}>
                  {scope.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Box>
    </>
  );
};

export default RegulationFields;
