import axios, { API_BASE_URL } from './axiosConfig';
import { UserData } from '../types/interfaces';

export const fetchUser = async (userId: number): Promise<UserData> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching user data');
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};
export const fetchCurrentUser = async (): Promise<UserData> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/me`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching current user data');
    }
  } catch (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
};

export const fetchUsernames = async (): Promise<
  Array<{ id: number; username: string }>
> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/users/usernames`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching usernames');
    }
  } catch (error) {
    console.error('Error fetching usernames:', error);
    throw error;
  }
};
