import React, { useState } from 'react';
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../modals/ConfirmationModal/ConfirmationModal';
import { TaskInstanceDocument } from '../../types/interfaces';

interface ArchivosComponentProps {
  taskInstance: any;
  onDownload: (documentId: number) => void;
  onDelete?: (documentId: number) => void;
  isDisabled?: boolean;
}

const ArchivosComponent: React.FC<ArchivosComponentProps> = ({
  taskInstance,
  onDownload,
  onDelete,
  isDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(
    null
  );

  const handleDeleteClick = (documentId: number) => {
    setSelectedDocumentId(documentId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedDocumentId !== null && onDelete) {
      onDelete(selectedDocumentId);
    }
    setIsModalOpen(false);
    setSelectedDocumentId(null);
  };

  const getName = (document: TaskInstanceDocument) => {
    return document.s3Key.split('-')[1];
  };

  const getDate = (document: TaskInstanceDocument) => {
    const date = new Date(parseInt(document.s3Key.split('-')[0]));
    const formattedDate = date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };

  return (
    <>
      <Typography variant="subtitle2" color={'secondary'}>
        Archivos
      </Typography>
      <Divider />
      {taskInstance?.documents?.map(
        (document: TaskInstanceDocument, index: number) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={4} mt={1}>
              <Typography fontSize={'14px'}>{getName(document)}</Typography>
            </Grid>
            <Grid item xs={4} mt={1}>
              <Typography fontSize={'14px'}>{getDate(document)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <IconButton onClick={() => onDownload(document.id)}>
                <DownloadIcon color="primary" />
              </IconButton>
              {onDelete && !isDisabled && (
                <IconButton onClick={() => handleDeleteClick(document.id)}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )
      )}
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        message="¿Estás seguro de que deseas eliminar este archivo?"
      />
      <br />
    </>
  );
};

export default ArchivosComponent;
