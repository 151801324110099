import { setCredentials } from '../store/slices/userSlice';
import { Dispatch } from '@reduxjs/toolkit';
import * as msal from '@azure/msal-browser';
import { msalConfig } from '../auth/msal';
import { LoginResponse } from '../types/interfaces';
import axios from './axiosConfig';
import { API_BASE_URL } from './axiosConfig';

const getAzureAccesToken = async () => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const account = msalInstance.getAllAccounts()[0];

  if (!account) {
    throw new Error('No hay cuenta activa en MSAL');
  }

  const silentRequest = {
    scopes: ['user.read'], // Ajusta según los scopes que necesitas.
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    console.error('Error al obtener el token de acceso con MSAL', error);
    throw error;
  }
};

export const login = async (dispatch: Dispatch): Promise<LoginResponse> => {
  const accessToken = await getAzureAccesToken();

  try {
    const response = await fetch(`${API_BASE_URL}/auth/login`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error de autenticación');
    }

    const data = await response.json();

    if (data.access_token) {
      sessionStorage.setItem('access_token', data.access_token);
      dispatch(setCredentials({ user: data.user_data }));

      return { auth: true, user_data: data.user_data };
    } else {
      return { auth: false, user_data: null };
    }
  } catch (error) {
    console.error('Error:', error);
    return { auth: false, user_data: null };
  }
};

export const verifyToken = async (token: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/auth/verifyToken`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.isValid;
  } catch (error) {
    console.error('Error verifying token:', error);
    return false;
  }
};
