
import axios, { API_BASE_URL } from './axiosConfig';
import { Regulation } from '../components/DataTable/dataTypes';

export const fetchRegulations = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/regulations?relations=client,emittedBy,group,category,type,document,scope,tasks,tasks.taskUserResponsibilities,tasks.taskUserResponsibilities.user,tasks.taskUserResponsibilities.responsibility,tasks.worksheets`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching regulations:', error);
    throw error;
  }
};

//CRUD REGULATIONS
export const createRegulation = async (
  newRegulation: Regulation
): Promise<Regulation> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/regulations`,
      newRegulation,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating regulation:', error);
    throw error;
  }
};
export const updateRegulation = async (
  regulation: Regulation
): Promise<Regulation> => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/regulations/${regulation.id}`,
      regulation,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.access_token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating regulation:', error);
    throw error;
  }
};
export const deleteRegulation = async (regulationId: number): Promise<void> => {
  try {
    await axios.delete(`${API_BASE_URL}/regulations/${regulationId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.access_token}`,
      },
    });
  } catch (error) {
    console.error('Error deleting regulation:', error);
    throw error;
  }
};

//REGULATION SATELLITES
export const fetchClients = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/clients`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};
export const fetchGroups = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/groups`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching groups:', error);
    throw error;
  }
};
export const fetchEmitters = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/emitters`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching emitters:', error);
    throw error;
  }
};
export const fetchTypes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/types`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching types:', error);
    throw error;
  }
};
export const fetchCategories = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/categories`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};
export const fetchScopes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/regulations/scopes`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};