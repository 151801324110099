import { useContext, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CalendarContext, {
  CalendarContextType,
} from '../ScheduleDashboard/CalendarContext';
import { TaskInstance } from '../../types/interfaces';
import './styles.css';
import CalendarEventContent from './CalendarEventContent';
import FilterControls from './FilterControls';
import { getTaskStylesAndPendingStatus } from './getTaskStylesAndPendingStatus';
import { SelectChangeEvent, useTheme } from '@mui/material';

const CalendarComponent = () => {
  const context = useContext(CalendarContext) as CalendarContextType;
  const theme = useTheme();
  const { setSelectedEvent } = context;

  const taskInstances = useSelector(
    (state: RootState) => state.taskInstances.taskInstances as TaskInstance[]
  );

  const [filterStatus, setFilterStatus] = useState<string>('all');
  const [filterText, setFilterText] = useState<any>();
  const [filterGroup, setFilterGroup] = useState<string>('all');
  const user = useSelector((state: RootState) => state.user.user);

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterStatus(event.target.value);
  };

  const handleTextFilterChange = (
    event: any,
    value: any,
    reason: any,
    details?: any
  ) => {
    setFilterText(value || null);
  };

  const handleGroupFilterChange = (event: SelectChangeEvent<string>) => {
    setFilterGroup(event.target.value);
  };

  const groups = [
    ...new Set(taskInstances.map((ti) => ti.task?.regulation?.group?.group)),
  ].filter((group) => group !== undefined) as string[];

  const filteredTaskInstances = taskInstances.filter((taskInstance) => {
    const eventDate = new Date(taskInstance.dueDate);
    const preparationDays = taskInstance.task?.preparationDays || 0;
    const responsibilities = taskInstance.task?.taskUserResponsibilities || [];

    const userResponsibility = responsibilities.find(
      (responsibility) => responsibility.user?.id === user.id
    )?.responsibility?.description;

    const { isPending } = getTaskStylesAndPendingStatus(
      theme,
      taskInstance.status,
      eventDate,
      preparationDays
    );

    const matchesTextFilter = taskInstance.task?.regulation?.code
      .toLowerCase()
      .includes(filterText?.toLowerCase() || '');

    const matchesGroupFilter =
      filterGroup === 'all' ||
      taskInstance.task?.regulation?.group?.group === filterGroup;

    if (filterStatus === 'pending') {
      const isPendingForRole = (() => {
        switch (userResponsibility) {
          case 'Principal':
            return isPending;
          case 'Revisión':
            return (
              (taskInstance.status === 'Revisión' ||
                taskInstance.status === 'Pendiente') &&
              isPending
            );
          case 'Ejecución':
            return (
              (taskInstance.status === 'Pendiente' ||
                taskInstance.status === 'Trabajando' ||
                taskInstance.status === 'Revisión Rechazada') &&
              isPending
            );
          case 'Validación':
            return (
              (taskInstance.status === 'Pendiente' ||
                taskInstance.status === 'Validación' ||
                taskInstance.status === 'Validación Rechazada') &&
              isPending
            );
          default:
            return false;
        }
      })();

      return isPendingForRole && matchesTextFilter && matchesGroupFilter;
    }

    if (filterStatus === 'completed') {
      return (
        taskInstance.status === 'Finalizado' &&
        matchesTextFilter &&
        matchesGroupFilter
      );
    }

    return matchesTextFilter && matchesGroupFilter;
  });

  const calendarEvents = filteredTaskInstances.map((taskInstance) => ({
    title: `${taskInstance.status[0]} ${taskInstance.task?.regulation?.code} (T${taskInstance.task?.number})`,
    date: taskInstance.dueDate,
    details: taskInstance,
  }));

  const handleEventClick = (clickInfo: any) => {
    const regulationDetails = clickInfo.event.extendedProps.details;
    setSelectedEvent({
      details: regulationDetails,
    });
  };

  const taskCodes = [
    ...new Set(taskInstances.map((ti) => ti.task?.regulation?.code)),
  ].filter((code) => code !== undefined) as string[];

  return (
    <>
      <FilterControls
        filterStatus={filterStatus}
        filterText={filterText}
        taskCodes={taskCodes}
        filterGroup={filterGroup}
        groups={groups}
        handleFilterChange={handleFilterChange}
        handleTextFilterChange={handleTextFilterChange}
        handleGroupFilterChange={handleGroupFilterChange}
      />
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,listWeek',
        }}
        buttonText={{
          month: 'Mes',
          list: 'Semana',
        }}
        height={'85vh'}
        locale={esLocale}
        events={calendarEvents}
        displayEventTime={false}
        eventDisplay="block"
        eventBackgroundColor="white"
        eventBorderColor="white"
        eventTextColor="black"
        dayMaxEvents={true}
        eventClick={handleEventClick}
        handleWindowResize={true}
        eventContent={(eventInfo) => (
          <CalendarEventContent eventInfo={eventInfo} />
        )}
      />
    </>
  );
};

export default CalendarComponent;
