import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import TaskBar from '../TaskBar/TaskBar';
import { CalendarProvider } from './CalendarContext';
import CalendarComponent from '../CalendarComponent/CalendarComponent';

const ScheduleDashboard: React.FC = () => {
  return (
    <div>
      <CalendarProvider>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8.8}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Calendario</Typography>
              <CalendarComponent />
            </Paper>
          </Grid>
          <Grid item xs={12} md={3.2}>
            <Paper sx={{ padding: 2, height: '100%' }}>
              <Typography variant="h6" pb={1.2}>
                Tarea Seleccionada
              </Typography>
              <TaskBar />
            </Paper>
          </Grid>
        </Grid>
      </CalendarProvider>
    </div>
  );
};

export default ScheduleDashboard;
