import React from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { TaskInstance } from '../../types/interfaces';

interface ApprovedStatusProps {
  approvedStatus: string | undefined;
  onApprove: () => void;
  editedTaskInstance: TaskInstance | null;
}

const ApprovedStatus: React.FC<ApprovedStatusProps> = ({
  approvedStatus,
  onApprove,
  editedTaskInstance,
}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const responsibilities =
    editedTaskInstance?.task?.taskUserResponsibilities || [];
  const userResponsibility = responsibilities.find(
    (responsibility) => responsibility.user?.id === user.id
  )?.responsibility?.description;

  const canApprove =
    userResponsibility === 'Principal' || userResponsibility === 'Validación';

  return (
    <>
      <Typography variant="subtitle2" color={'secondary'} sx={{ mt: 2 }}>
        Estado de Aprobación
      </Typography>
      <Divider sx={{ mb: 1 }} />
      {(() => {
        switch (approvedStatus) {
          case 'aprobada':
            return (
              <Box display="flex" alignItems="center" sx={{ color: 'green' }}>
                <CheckCircleIcon />
                <Typography variant="subtitle2" sx={{ ml: 1 }}>
                  Aprobada
                </Typography>
              </Box>
            );
          case 'finalizada fuera de plazo':
            return (
              <Box display="flex" alignItems="center" sx={{ color: 'red' }}>
                <ErrorIcon />
                <Typography variant="subtitle2" sx={{ ml: 1 }}>
                  Finalizada fuera de plazo
                </Typography>
                {canApprove && (
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ ml: 2 }}
                    onClick={onApprove}
                  >
                    Dentro de plazo
                  </Button>
                )}
              </Box>
            );
          default:
            return (
              <Box display="flex" alignItems="center" sx={{ color: 'orange' }}>
                <ErrorIcon />
                <Typography variant="subtitle2" sx={{ ml: 1 }}>
                  Pendiente
                </Typography>
                {canApprove && (
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ ml: 2 }}
                    onClick={onApprove}
                  >
                    Dentro de plazo
                  </Button>
                )}
              </Box>
            );
        }
      })()}
    </>
  );
};

export default ApprovedStatus;
