import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  fetchTaskInstanceMessages,
  sendTaskInstanceMessage,
} from '../../api/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface TaskMessagesProps {
  taskInstanceId: number;
}

const TaskMessages: React.FC<TaskMessagesProps> = ({ taskInstanceId }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const data = await fetchTaskInstanceMessages(taskInstanceId);
        setMessages(data);
      } catch (error) {
        console.error('Error loading messages:', error);
      }
    };

    loadMessages();
  }, [taskInstanceId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const newMsg = await sendTaskInstanceMessage(
        taskInstanceId,
        newMessage,
        user.id
      );
      setMessages([...messages, newMsg]);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Box>
      <List>
        {messages.map((msg) => (
          <ListItem key={msg.id}>
            <ListItemText
              primary={
                <>
                  <Typography variant="subtitle2">
                    {msg.sender.username} ({msg.responsibilityDescription})
                  </Typography>
                  <Typography variant="caption">
                    {new Date(msg.sentAt).toLocaleString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </Typography>
                </>
              }
              secondary={msg.message}
            />
          </ListItem>
        ))}
      </List>
      <TextField
        label="Escribe un mensaje"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendMessage}
        disabled={!newMessage.trim()}
        style={{ marginTop: '10px' }}
      >
        Enviar
      </Button>
    </Box>
  );
};

export default TaskMessages;
