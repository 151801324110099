import { TaskInstance } from '../types/interfaces';
import axios from './axiosConfig';
import { API_BASE_URL } from './axiosConfig';

export const fetchTaskInstances = async (userId: number): Promise<TaskInstance[]> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/task-instances/${userId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching task instances:', error);
    throw error;
  }
};

export const getTaskInstance = async (taskInstanceId: number): Promise<TaskInstance> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/task-instance/${taskInstanceId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching task instance:', error);
    throw error;
  }
}

export const createTaskInstance = async (newTaskInstance: TaskInstance): Promise<TaskInstance> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/tasks/task-instances`,
      newTaskInstance,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating task instance:', error);
    throw error;
  }
};

export const updateTaskInstance = async (taskInstance: TaskInstance, userId: number): Promise<TaskInstance> => {
  try {
    const response = await axios.patch(
      `${API_BASE_URL}/tasks/task-instances/${taskInstance.id}`,
      { ...taskInstance, userId },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating task instance:', error);
    throw error;
  }
};

export const deleteTaskInstance = async (taskInstanceId: number): Promise<void> => {
  try {
    await axios.delete(`${API_BASE_URL}/tasks/task-instances/${taskInstanceId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
  } catch (error) {
    console.error('Error deleting task instance:', error);
    throw error;
  }
};

export const uploadTaskInstanceDocument = async (file: File, taskInstanceId: number): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('taskInstanceId', taskInstanceId.toString());

  try {
    const response = await axios.post(
      `${API_BASE_URL}/tasks/task-instances/documents`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );
    return response.data.uploadUrl;
  } catch (error) {
    console.error('Error uploading task instance document:', error);
    throw error;
  }
};

export const downloadTaskInstanceDocument = async (documentId: number): Promise<{ blob: Blob; fileName: string }> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/task-instances/documents/${documentId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
      responseType: 'json', // Recibir la respuesta como JSON
    });

    const { file, fileName, contentType } = response.data;

    // Convertir el archivo base64 a Blob
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    return {
      blob,
      fileName,
    };
  } catch (error) {
    console.error('Error downloading task instance document:', error);
    throw error;
  }
};

export const deleteTaskInstanceDocument = async (documentId: number): Promise<void> => {
  try {
    await axios.delete(`${API_BASE_URL}/tasks/task-instances/documents/${documentId}`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
  } catch (error) {
    console.error('Error deleting task instance document:', error);
    throw error;
  }
}

export const fetchTaskInstanceMessages = async (taskInstanceId: number): Promise<any[]> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/task-instances/${taskInstanceId}/messages`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching task instance messages:', error);
    throw error;
  }
};

export const sendTaskInstanceMessage = async (taskInstanceId: number, message: string, userId: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/tasks/task-instances/${taskInstanceId}/messages`,
      {
        message, userId
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending task instance message:', error);
    throw error;
  }
};

