import axios from 'axios';

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

axios.defaults.baseURL = API_BASE_URL;

// Agregar interceptor de respuesta
axios.interceptors.response.use(
  (response) => {
    // Si la respuesta es exitosa, simplemente la devuelve sin modificar.
    return response;
  },
  (error) => {
    // Chequea si el error es un error 401 - no autorizado.
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('access_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axios;
