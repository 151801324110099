import React, { useState } from 'react';
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../modals/ConfirmationModal/ConfirmationModal';
import { TaskWorksheet } from '../../types/interfaces';

interface TaskWorksheetsComponentProps {
  worksheets: any[];
  onDownload: (worksheetId: number) => void;
  onDelete?: (worksheetId: number) => void;
  isDisabled?: boolean;
}

const TaskWorksheetsComponent: React.FC<TaskWorksheetsComponentProps> = ({
  worksheets,
  onDownload,
  onDelete,
  isDisabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWorksheetId, setSelectedWorksheetId] = useState<number | null>(
    null
  );

  const handleDeleteClick = (worksheetId: number) => {
    setSelectedWorksheetId(worksheetId);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedWorksheetId !== null && onDelete) {
      onDelete(selectedWorksheetId);
    }
    setIsModalOpen(false);
    setSelectedWorksheetId(null);
  };

  const getName = (worksheet: TaskWorksheet) => {
    return worksheet.s3Key.split('-')[1];
  };

  const getDate = (worksheet: any) => {
    const date = new Date(parseInt(worksheet.s3Key.split('-')[0]));
    const formattedDate = date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    return formattedDate;
  };

  return (
    <>
      <Typography variant="subtitle2" color={'secondary'}>
        Templates
      </Typography>
      <Divider />
      {worksheets?.map((worksheet, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={4} mt={1}>
            <Typography fontSize={'14px'}>{getName(worksheet)}</Typography>
          </Grid>
          <Grid item xs={4} mt={1}>
            <Typography fontSize={'14px'}>{getDate(worksheet)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <IconButton onClick={() => onDownload(worksheet.id)}>
              <DownloadIcon color="primary" />
            </IconButton>
            {onDelete && !isDisabled && (
              <IconButton onClick={() => handleDeleteClick(worksheet.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
        message="¿Estás seguro de que deseas eliminar este Template?"
      />
    </>
  );
};

export default TaskWorksheetsComponent;
