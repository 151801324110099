import {
  updateTaskInstance,
  uploadTaskInstanceDocument,
  deleteTaskInstanceDocument,
  getTaskInstance,
  downloadTaskInstanceDocument,
} from '../../api/taskInstancesApi';
import { Dispatch, SetStateAction } from 'react';
import { TaskInstance } from '../../types/interfaces';
import { AppDispatch } from '../../store/store';

export const handleFileChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setSelectedFile: Dispatch<SetStateAction<File | null>>
) => {
  if (event.target.files && event.target.files.length > 0) {
    setSelectedFile(event.target.files[0]);
  }
};

export const handleUploadFile = async (
  editedTaskInstance: TaskInstance | null,
  selectedFile: File | null,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setEditedTaskInstance: Dispatch<SetStateAction<TaskInstance | null>>,
  setSelectedFile: Dispatch<SetStateAction<File | null>>,
  handleCloseModal: () => void
) => {
  if (editedTaskInstance?.id && selectedFile) {
    setLoading(true);
    try {
      await uploadTaskInstanceDocument(selectedFile, editedTaskInstance.id);
      const taskInstance = await getTaskInstance(editedTaskInstance.id);
      setEditedTaskInstance((prevInstance) =>
        prevInstance
          ? { ...prevInstance, documents: taskInstance.documents }
          : null
      );
      setSelectedFile(null);
      setLoading(false);
      handleCloseModal();
    } catch (error) {
      console.error('Error uploading file:', error);
      setLoading(false);
    }
  }
};

export const handleDeleteDocument = async (
  documentId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setEditedTaskInstance: Dispatch<SetStateAction<TaskInstance | null>>
) => {
  setLoading(true);
  try {
    await deleteTaskInstanceDocument(documentId);
    setEditedTaskInstance((prevInstance) =>
      prevInstance
        ? {
            ...prevInstance,
            documents: prevInstance?.documents?.filter(
              (doc) => doc.id !== documentId
            ),
          }
        : null
    );
    setLoading(false);
  } catch (error) {
    console.error('Error deleting document:', error);
    setLoading(false);
  }
};

export const handleDownloadDocument = async (documentId: number) => {
  try {
    const { blob, fileName } = await downloadTaskInstanceDocument(documentId);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  } catch (error) {
    console.error('Error downloading document:', error);
  }
};

export const handleSave = async (
  editedTaskInstance: TaskInstance | null,
  setLoading: Dispatch<SetStateAction<boolean>>,
  dispatch: AppDispatch,
  updateTaskInstanceAsync: any,
  setOriginalTaskInstance: Dispatch<SetStateAction<TaskInstance | null>>,
  userId: number
) => {
  if (editedTaskInstance) {
    setLoading(true);
    const currentDate = new Date();
    const dueDate = new Date(editedTaskInstance.dueDate);

    const truncatedCurrentDate = new Date(currentDate.toDateString());
    const truncatedDueDate = new Date(dueDate.toDateString());

    if (
      editedTaskInstance.status === 'Finalizado' &&
      !editedTaskInstance.completionDate
    ) {
      editedTaskInstance.completionDate = currentDate;
      if (truncatedCurrentDate <= truncatedDueDate) {
        editedTaskInstance.approvedStatus = 'aprobada';
      } else {
        editedTaskInstance.approvedStatus = 'finalizada fuera de plazo';
      }
    }

    try {
      const ti = await updateTaskInstance(editedTaskInstance, userId);
      await dispatch(updateTaskInstanceAsync({ ...ti, userId }));
      setOriginalTaskInstance(ti);
    } catch (error) {
      console.error('Error saving task instance:', error);
    } finally {
      setLoading(false);
    }
  }
};
