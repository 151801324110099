import { useSelector } from 'react-redux';
import ScheduleDashboard from '../ScheduleDashboard/ScheduleDashboard';

function Dashboard() {
  const userName = useSelector((state: any) => state.user?.user?.username);

  return (
    <>
      {/* Verifica si userName existe para mostrar el mensaje de bienvenida */}
      <div style={{ marginTop: '-30px', marginBottom: '-25px' }}>
        {userName ? (
          <h4 style={{ fontWeight: 'normal' }}>Bienvenido, {userName}</h4>
        ) : (
          <h4>Bienvenido, usuario</h4>
        )}
      </div>
      <ScheduleDashboard />
    </>
  );
}

export default Dashboard;
