import React from 'react';
import { Controller, Control } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import {
  RegulationFormValues,
  ResponsibilityType,
  User,
} from '../../DataTable/dataTypes';

interface ResponsibilityFieldsProps {
  control: Control<RegulationFormValues>;
  taskIndex: number;
  users: User[];
  types: ResponsibilityType[];
  disabled?: boolean;
}

const ResponsibilityFields: React.FC<ResponsibilityFieldsProps> = ({
  control,
  taskIndex,
  users,
  types,
  disabled,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="10px"
      mb="20px"
      minWidth="300px"
    >
      {types.map((type, responsibilityIndex) => (
        <Box key={responsibilityIndex}>
          <Box mt={2} mb={2}>
            <Typography variant="body2" color="textPrimary">
              {type.description}
            </Typography>
          </Box>

          <FormControl variant="outlined" fullWidth>
            <InputLabel id={`user-label-${taskIndex}-${responsibilityIndex}`}>
              Responsable
            </InputLabel>
            <Controller
              name={`tasks.${taskIndex}.taskUserResponsibilities.${responsibilityIndex}.user`}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  labelId={`user-label-${taskIndex}-${responsibilityIndex}`}
                  label="Responsable"
                  value={field.value?.id || ''}
                  onChange={(event) => {
                    const selectedUser = users.find(
                      (user) => user.id === event.target.value
                    );
                    field.onChange(selectedUser);
                  }}
                  disabled={disabled}
                  error={!!fieldState.error}
                >
                  {users
                    .sort((a, b) => a.username.localeCompare(b.username))
                    .map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.username}
                      </MenuItem>
                    ))}
                </Select>
              )}
              rules={{
                required:
                  'Debe seleccionar un usuario para esta responsabilidad',
              }}
            />
          </FormControl>
        </Box>
      ))}
    </Box>
  );
};

export default ResponsibilityFields;
