import axios from 'axios';
import { API_BASE_URL } from './axiosConfig';


export const uploadTaskWorksheet = async (file: File, taskId: number) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('taskId', taskId.toString());

  const response = await axios.post(
    `${API_BASE_URL}/tasks/worksheets`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

// export const downloadTaskWorksheet = async (worksheetId: number): Promise<{ blob: Blob; fileName: string }> => {
//   const response = await axios.get(
//     `${API_BASE_URL}/tasks/worksheets/${worksheetId}`,
//     {
//       headers: {
//         Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
//       },
//       responseType: 'blob',
//     }
//   );

//   const url = window.URL.createObjectURL(new Blob([response.data]));
//   return url;
// };

export const downloadTaskWorksheet = async (worksheetId: number): Promise<{ blob: Blob; fileName: string }> => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/tasks/worksheets/${worksheetId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
        responseType: 'json', // Recibir la respuesta como JSON
      });

    const { file, fileName, contentType } = response.data;

    // Convertir el archivo base64 a Blob
    const byteCharacters = atob(file);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    return {
      blob,
      fileName,
    };
  } catch (error) {
    console.error('Error downloading task instance document:', error);
    throw error;
  }
};

export const deleteTaskWorksheet = async (worksheetId: number) => {
  await axios.delete(`${API_BASE_URL}/tasks/worksheets/${worksheetId}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
    },
  });
};
