import { getTaskStylesAndPendingStatus } from './getTaskStylesAndPendingStatus';
import { useTheme } from '@mui/material';

const CalendarEventContent = ({ eventInfo }: { eventInfo: any }) => {
  const theme = useTheme();
  const title = eventInfo.event.title;
  const status = eventInfo.event.extendedProps.details.status;
  const approvedStatus = eventInfo.event.extendedProps.details.approvedStatus;
  let firstLetter = title.charAt(0);
  if (status === 'Revisión Rechazada') firstLetter = 'RR';
  if (status === 'Validación Rechazada') firstLetter = 'VR';
  const restOfTitle = title.slice(1);

  const preparationDays =
    eventInfo?.event?.extendedProps?.details?.task?.preparationDays;
  const eventDate = eventInfo.event.start;

  const { styles } = getTaskStylesAndPendingStatus(
    theme,
    status,
    eventDate,
    preparationDays,
    approvedStatus
  );

  return (
    <div className="custom-event">
      <span style={styles}>{firstLetter}</span>
      <span className="event-title">{restOfTitle}</span>
    </div>
  );
};

export default CalendarEventContent;
