export const getTaskStylesAndPendingStatus = (
  theme: any,
  status: string,
  eventDate: Date,
  preparationDays: number,
  approvedStatus?: string
) => {
  const currentDate = new Date();
  const eventDateObj = new Date(eventDate);
  const preparationStartDate = new Date(eventDateObj);
  preparationStartDate.setDate(eventDateObj.getDate() - preparationDays);

  let color = theme.palette.text.primary;
  let backgroundColor;
  let isPending = false;

  const lastQuintile = new Date(eventDateObj);
  lastQuintile.setDate(eventDateObj.getDate() - preparationDays / 5);

  const isBeforePreparation = currentDate < preparationStartDate;
  const isWithinPreparation = currentDate >= preparationStartDate && currentDate <= eventDateObj;
  const isInLastQuintile = currentDate >= lastQuintile;

  const isReviewOrValidation =
    status === 'Revisión' ||
    status === 'Validación' ||
    status === 'Revisión Rechazada' ||
    status === 'Liberación' ||
    status === 'Validación Rechazada';

  if (status === 'Finalizado') {
    backgroundColor = theme.palette.success.main;
  } else if (isBeforePreparation) {
    backgroundColor = theme.palette.success.main;
  } else if (isWithinPreparation && !isInLastQuintile) {
    backgroundColor = theme.palette.warning.main;
    isPending = true;
  } else if (isInLastQuintile) {
    if (
      status === 'Pendiente' ||
      status === 'Trabajando' ||
      status === 'Revisión' ||
      status === 'Validación' ||
      status === 'Liberación' ||
      status === 'Revisión Rechazada' ||
      status === 'Validación Rechazada'
    ) {
      backgroundColor = theme.palette.error.main;
      isPending = true;
    } else {
      backgroundColor = theme.palette.success.main;
    }
  } else {
    if (isReviewOrValidation) {
      backgroundColor = theme.palette.error.main;
      isPending = true;
    } else {
      backgroundColor = theme.palette.success.main;
    }
  }

  if (approvedStatus === 'finalizada fuera de plazo') {
    backgroundColor = theme.palette.error.main;
  }

  return {
    styles: {
      color,
      backgroundColor,
      padding: '2px 4px',
      borderRadius: '3px',
      marginRight: '2px',
    },
    isPending,
  };
};
