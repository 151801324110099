import React, { useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Regulation,
  RegulationFormValues,
  ResponsibilityType,
  User,
} from '../DataTable/dataTypes';
import DocumentFields from './DocumentFields';
import RegulationFields from './RegulationFields';
import TaskFields from './Tasks/TaskFields';
import { fetchResponsibilities, fetchUsernames } from '../../api/api';
import LoadingModal from '../modals/LoadingModal/LoadingModal';

interface RegulationFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: Regulation) => void;
  regulation: Regulation | null;
  isReadOnly: boolean;
}

const RegulationFormDialog: React.FC<RegulationFormDialogProps> = ({
  open,
  onClose,
  onSave,
  regulation,
  isReadOnly,
}) => {
  const [responsibilities, setResponsibilities] = useState<ResponsibilityType[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getUsernames = async () => {
      const usernames = await fetchUsernames();
      setUsers(usernames);
    };

    const getResponsibilities = async () => {
      const responsibilities = await fetchResponsibilities();
      setResponsibilities(responsibilities);
    };

    getUsernames();
    getResponsibilities();
  }, []);

  const defaultValues = useMemo(
    () => ({
      code: '',
      group: { id: 0 },
      emitedBy: { id: 0 },
      type: { id: 0 },
      scope: { id: 0 },
      category: { id: 0 },
      number: '',
      document: null,
      tasks: [],
    }),
    []
  );

  const methods = useForm<RegulationFormValues>({
    defaultValues,
    mode: 'all',
  });

  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    if (regulation) {
      const regulationForReset = {
        ...regulation,
        clientId: regulation.client?.id,
        groupId: regulation.group?.id,
        emittedById: regulation.emittedBy?.id,
        scopeId: regulation.scope?.id,
        typeId: regulation.type?.id,
        categoryId: regulation.category?.id,
      };
      reset(regulationForReset);
    } else {
      reset(defaultValues);
    }
  }, [regulation, reset, defaultValues]);

  const onSubmit: SubmitHandler<RegulationFormValues> = async (formData) => {
    setIsLoading(true);
    try {
      if (regulation?.id) {
        const updatedRegulation: Regulation = {
          ...formData,
          id: regulation.id,
        };
        await onSave(updatedRegulation);
      } else {
        await onSave(formData);
      }
    } catch (error) {
      console.error('Error al guardar:', error);
    } finally {
      setIsLoading(false);
      onClose(); 
    }
  };

  return (
    <>
      {isLoading && <LoadingModal open={true} />}
      <Dialog
        open={open && !isLoading} 
        maxWidth="lg"
        fullWidth
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
      >
        <DialogTitle>
          {regulation
            ? isReadOnly
              ? 'Ver Normativa'
              : 'Editar Normativa'
            : 'Crear Normativa'}
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormProvider {...methods}>
            <RegulationFields control={control} disabled={isReadOnly} />
            <DocumentFields control={control} disabled={isReadOnly} />
            <TaskFields
              control={control}
              users={users}
              types={responsibilities}
              disabled={isReadOnly}
            />
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          {!isReadOnly && (
            <Button type="submit" color="secondary" disabled={isLoading}>
              {regulation ? 'Guardar Cambios' : 'Crear'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegulationFormDialog;
