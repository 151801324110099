import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { RootState } from '../../components/DataTable/dataTypes'; // Ajustar si es necesario

// Definir el tipo de notificaciones en base a lo que tenemos en Redux
interface NotificationState {
  'Fuera de Plazo'?: number | null;
  'Validación Rechazada'?: number | null;
  'Revisión Rechazada'?: number | null;
  'Ejecución Atrasada'?: number | null;
  'Liberación'?: number | null;
}

export default function NotificationsMenu() {
  // Accedemos a la parte del estado de Redux correspondiente a las notificaciones
  const notifications = useSelector((state: RootState) => state.notifications.notifications as NotificationState);

  // Estado del menú
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Contador de notificaciones (podemos sumar las que tienen un valor mayor a 0)
  const totalNotifications = Object.values(notifications).filter((value) => value).length;

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Box
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {/* Mostramos la campanita con el número total de notificaciones */}
          <Badge badgeContent={totalNotifications} color="error">
            <NotificationsIcon />
          </Badge>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <List>
          {/* Renderizamos solo las notificaciones que existen */}
          {Object.entries(notifications).map(([key, value]) => 
            value ? (
              <MenuItem key={key}>
                <ListItem>
                  <ListItemText primary={`${key}: ${value}`} />
                </ListItem>
              </MenuItem>
            ) : null
          )}
        </List>
      </Menu>
    </React.Fragment>
  );
}
