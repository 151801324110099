import React, { useMemo, useState } from 'react';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addRegulationAsync,
  deleteRegulationAsync,
  updateRegulationAsync,
} from '../../store/slices/regulationSlice';
import { AppDispatch, RootState } from '../../store/store';
import { Regulation } from './dataTypes';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RegulationFormDialog from '../RegulationFormDialog/RegulationFormDialog';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { RegulationDetailCard } from '../RegulationDetailCard/RegulationDetailCard';
import Spinner from '../Spinner/Spinner';
import DetailItem from '../DetailItem/DetailItem';
import DialogPdf from '../DialogPdf/DialogPdf';
import { getTaskInstances } from '../../store/slices/taskInstanceSlice';

const DataTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.user);
  const userRoles = useMemo(
    () => (user ? user.roles.map((role) => role.name) : []),
    [user]
  );
  const [isReadOnly, setIsReadOnly] = useState(false);

  const columns = useMemo<MRT_ColumnDef<Regulation>[]>(
    () => [
      {
        accessorKey: 'group.group',
        header: 'Grupo',
        size: 1,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'code',
        header: 'Código',
        size: 1,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'document.referenceTitle',
        header: 'Título',
        size: 2,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'type.type',
        header: 'Tipo',
        size: 1,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'scope.name',
        header: 'Alcance',
        size: 1,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'category.category',
        header: 'Categoría',
        size: 1,
        enableColumnFilter: true,
        filterFn: 'includesString',
      },
      {
        accessorKey: 'document.pdfUrl',
        header: 'PDF',
        size: 1,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const url = cell.getValue() as string;
          const [open, setOpen] = React.useState(false);

          const handleClick = () => {
            if (true) {
              window.open(url, '_blank');
            } else {
              setOpen(true);
            }
          };

          const handleClose = () => {
            setOpen(false);
          };

          return (
            <div>
              <IconButton onClick={handleClick}>
                <OpenInNewIcon color={'primary'} />
              </IconButton>
              <DialogPdf open={open} onClose={handleClose} setOpen={setOpen} />
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: '',
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', ml: -1 }}>
            {/* Todos los usuarios pueden ver */}
            <Tooltip title="Visualizar">
              <IconButton onClick={() => handleOpenViewDialog(row.original)}>
                <VisibilityIcon color="primary" />
              </IconButton>
            </Tooltip>

            {/* Solo supervisor y admin pueden editar */}
            {(userRoles.includes('supervisor') ||
              userRoles.includes('admin')) && (
              <Tooltip title="Editar">
                <IconButton onClick={() => handleOpenEditDialog(row.original)}>
                  <EditIcon color="warning" />
                </IconButton>
              </Tooltip>
            )}

            {/* Solo supervisor y admin pueden eliminar */}
            {(userRoles.includes('supervisor') ||
              userRoles.includes('admin')) && (
              <Tooltip title="Eliminar">
                <IconButton
                  onClick={() => handleOpenDeleteDialog(row.original.id)}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
    ],
    [userRoles] // Incluye userRoles en las dependencias para que se re-renderice si cambia
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRegulation, setCurrentRegulation] = useState<Regulation | null>(
    null
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [regulationToDelete, setRegulationToDelete] = useState<number | null>(
    null
  );

  const handleOpenDeleteDialog = (regulationId: number | undefined) => {
    if (!regulationId) return;
    setRegulationToDelete(regulationId);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (regulationToDelete) {
      dispatch(deleteRegulationAsync(regulationToDelete)).then(() => {
        handleCloseDeleteDialog();
      });
    }
  };

  const handleOpenCreateDialog = () => {
    setCurrentRegulation(null);
    setIsDialogOpen(true);
  };

  const handleOpenEditDialog = (regulation: Regulation) => {
    setCurrentRegulation(regulation);
    setIsReadOnly(false);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSaveRegulation = async (data: Regulation) => {
    if (data.id) {
      await dispatch(updateRegulationAsync(data));
    } else {
      await dispatch(addRegulationAsync(data));
    }
    await dispatch(getTaskInstances(user.id));

    setIsDialogOpen(false);
  };

  const handleOpenViewDialog = (regulation: Regulation) => {
    setCurrentRegulation(regulation);
    setIsReadOnly(true); // Activa el modo de solo lectura
    setIsDialogOpen(true);
  };

  const regulations = useSelector(
    (state: RootState) => state.regulation.regulations as Regulation[]
  );
  const loading = useSelector((state: RootState) => state.regulation.loading);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <h3>Mantenedor de Normativas</h3>
      </Box>
      <MaterialReactTable
        columns={columns}
        data={regulations}
        localization={MRT_Localization_ES}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableHiding={false}
        enableColumnActions={true}
        initialState={{ showGlobalFilter: true }}
        createDisplayMode="modal"
        editDisplayMode="modal"
        renderTopToolbarCustomActions={() =>
          userRoles.includes('supervisor') || userRoles.includes('admin') ? (
            <Button onClick={handleOpenCreateDialog} color="secondary">
              Crear
            </Button>
          ) : (
            <div></div>
          )
        }
        muiExpandButtonProps={({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
          sx: {
            transform: row.getIsExpanded()
              ? 'rotate(180deg)'
              : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) => (
          <>
            {row.original.document && (
              <RegulationDetailCard title="Documento">
                <DetailItem
                  title="Título de Referencia"
                  content={row.original.document.referenceTitle}
                />
                <DetailItem
                  title="Descripción"
                  content={row.original.document.description}
                />
              </RegulationDetailCard>
            )}
          </>
        )}
      />
      {isDialogOpen ? (
        <RegulationFormDialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          onSave={handleSaveRegulation}
          regulation={currentRegulation}
          isReadOnly={isReadOnly}
        />
      ) : null}

      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        title="Confirmar eliminación"
        message="¿Estás seguro de que deseas eliminar esta normativa?"
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseDeleteDialog}
      />
    </>
  );
};

export default DataTable;
