import { ChangeEvent } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  SelectChangeEvent,
} from '@mui/material';

type FilterControlsProps = {
  filterStatus: string;
  filterText: string;
  taskCodes: string[];
  filterGroup: string;
  groups: string[];
  handleFilterChange: any;
  handleTextFilterChange: (
    event: ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
  handleGroupFilterChange: (event: SelectChangeEvent<string>) => void;
};

const FilterControls = ({
  filterStatus,
  filterText,
  taskCodes,
  filterGroup,
  groups,
  handleFilterChange,
  handleTextFilterChange,
  handleGroupFilterChange,
}: FilterControlsProps) => (
  <Box display="flex" justifyContent="flex-end" mb={2}>
    <Autocomplete
      options={taskCodes}
      noOptionsText="No hay coincidencias"
      getOptionLabel={(option) => option || ''}
      value={filterText}
      onChange={handleTextFilterChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Filtrar por código"
          sx={{ mt: -4 }}
        />
      )}
      sx={{ width: 300 }}
    />
    <FormControl variant="outlined" size="small" sx={{ mt: -4, ml: 2 }}>
      <InputLabel>Estado</InputLabel>
      <Select value={filterStatus} onChange={handleFilterChange} label="Estado">
        <MenuItem value="all">Todos</MenuItem>
        <MenuItem value="pending">En Curso</MenuItem>
        <MenuItem value="completed">Completadas</MenuItem>
      </Select>
    </FormControl>
    <FormControl variant="outlined" size="small" sx={{ mt: -4, ml: 2 }}>
      <InputLabel>Grupo</InputLabel>
      <Select
        value={filterGroup}
        onChange={handleGroupFilterChange}
        label="Grupo"
      >
        <MenuItem value="all">Todos</MenuItem>
        {groups.map((group) => (
          <MenuItem key={group} value={group}>
            {group}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

export default FilterControls;
